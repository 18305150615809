<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4" v-if='$store.state.app.user_data&&($store.state.app.user_data.role== "employee" || $store.state.app.user_data.role== "admin")'>
            <b-form-group>
              <label>Assign To</label>
                <v-select v-model="assigntotype" placeholder="None" :options="['Employee','Channelpartner']" @input="handleEmployeeOption">
                </v-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Assign To">
                <v-select v-model="assignto" label="fullname" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None" :options="userOptions" >
                  <template
                    #option="{ fullname, profile_image, username, color }"
                  >
                    <b-avatar :src="getprofileImage(profile_image)" />
                    <span :class="'font-weight-bolder text-' + color">
                      {{ fullname }} ({{ username }})</span
                    >
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Task Type</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Task Type">
                <v-select v-model="tasktype" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                  placeholder="None" :options="['Office Work', 'Personal Work', 'Social Work']" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Task</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Task">
                <b-form-input v-model="task" type="text" :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="Enter Task" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Priority</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Priority">
                <v-select v-model="priority" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                  placeholder="None" :options="['High', 'Medium', 'Low']" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Description</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Description">
                <b-form-textarea placeholder="Enter Description" v-model="description" rows="2" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Status</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Status">
                <v-select v-model="status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                  placeholder="None" :options="['Open', 'Pending', 'Complete']" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Dead Line</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Dead Line">
                <flat-pickr v-model="deadline" class="form-control" :config="{
                  dateFormat: 'd/m/Y',
                }" style="background-color: transparent" />
                <!-- <b-form-input
                  v-model="deadline"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Dead Line"
                /> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Attachment 1</label>
              <b-form-file
                v-model="documents.attachment1"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'task', 'attachment1')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="documents.attachment1" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Attachment 2</label>
              <b-form-file
                v-model="documents.attachment2"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'task', 'attachment2')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="documents.attachment2" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Remarks</label>
              <b-form-input v-model="remarks" type="text" placeholder="Enter Remarks" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="submitForm($event)" class="mr-4"
              :disabled="submitDisable">
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import Attachment from "../../../components/Attechment.vue";
import moment from 'moment'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormFile,
  BAvatar

} from "bootstrap-vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from '@/components/axios';

export default {
  components: {
    Attachment,
    PinchScrollZoom,
    flatPickr,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BFormFile,
    BAvatar


  },
  data() {
    return {
      documents: {
        attachment1: '',
        attachment2: '',
      },
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      assignto: "",
      employeeoption: [],
      tasktype: "",
      task: "",
      priority: "",
      description: "",
      status: "",
      deadline: null,
      remarks: "",
      ifEdit: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      id: "",
      submitDisable: false,
      userData: {},
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      assigntotype:'',
      cpoptions:[],
      userOptions:[]


    };
  },
  mounted() {
    this.userData=JSON.parse(localStorage.getItem('userData'))

    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    // if (role !== "admin") {
    //   userData.map((item) => {
    //     if (item.modulename == "Task") {
    //       if (this.$route.params.id) {
    //         if (item.edit !== 1) {
    //           this.$router.push("/crm/task");
    //         }
    //       } else if (item.add !== 1) {
    //         this.$router.push("/crm/task");
    //       }
    //     }
    //   });
    // }

    this.employeeoption=[]
    if(this.$store.state.app.user_data.role == 'employee'){
      this.employeeoption = this.$store.state.app.all_users.filter((item)=>{
            return item.role == 'employee'
      })
      this.cpoptions = this.$store.state.app.all_users.filter((item)=>{
            return item.assignto == this.$store.state.app.user_data.id
      })
    }
    else if(this.$store.state.app.user_data.role == 'admin'){
      this.employeeoption = this.$store.state.app.all_users.filter((item)=>{
          return item.role == 'employee'
      })
      this.cpoptions = this.$store.state.app.all_users.filter((item)=>{
          return item.role == 'channelpartner'

      })
    }
    else{
      this.getUsers();
    }

     this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();


  },
  methods: {
    async handleEmployeeOption(){
      this.userOptions =[]
      if(this.assigntotype == 'Employee'){
        this.userOptions = [...this.employeeoption]
      }
      else if(this.assigntotype == 'Channelpartner'){
        this.userOptions = [...this.cpoptions]
      }
    },
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath)
          ? image
          : this.imagePath + image;
        return test;
      }
      return image;
    },
    async handleChangeFile(e, type, name) {
      this.submitDisable = true;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };

      await axios(requestOptions)
        .then((response) => {
          this.documents[name] =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => console.log(error, "error"))
        .finally(()=>{
          this.submitDisable = false;

        })
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/gettaskById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          // }
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      item.map((item) => {
        this.assigntotype = item.assignto&&item.assignto.role == 'employee'?'Employee' :(item.assignto&&item.assignto.role == 'channlepartner'?'Channlepartner':'')
        this.assignto = item.assignto;
        this.tasktype = item.tasktype;
        this.task = item.task;
        this.priority = item.priority;
        this.description = item.description;
        this.status = item.status;
        this.deadline = moment(item.deadline).format('DD/MM/YYYY');
        this.remarks = item.remarks;
        this.documents.attachment1 = item.attachment1;
        this.documents.attachment2 = item.attachment2;
      });
      this.handleEmployeeOption()

    },
    async getUsers() {
      let url='';
      if(this.userData.role == 'admin'){
        url=`${this.baseApi}/getUsers`
      }
      else{
        url=`${this.baseApi}/getUserByParentId/${this.userData.id}`
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: url,
      };
      await axios(requestOptions)
        .then((response) => {
          this.userOptions = response.data.data;
          if(this.userData.role !== 'admin'){
            this.userOptions.map((item)=>{
                if(item.children.length >0){
                  this.handleChild(item.children)
                }
            })

            let employee = this.$store.state.app.all_users.filter( item => this.$store.state.app.user_data.assignto == item.id )
            this.userOptions=[...employee,...this.userOptions]
          }

        })
        .catch((error) => console.log(error, "error"));
    },
    handleChild(children){
      children.map((item)=>{
        this.userOptions.push(item)
        if(item.children.length>0){
          handleChild(item.children)
        }
      })

    },
    onClickBack() {
      this.$router.push("/crm/task");
    },
    submitForm(e) {
      const data = {
        assignto: this.assignto.id,
        tasktype: this.tasktype,
        task: this.task,
        priority: this.priority,
        description: this.description,
        status: this.status,
        deadline: moment(this.deadline,'DD/MM/YYYY').format('YYYY-MM-DD'),
        remarks: this.remarks,
        attachment1: this.documents.attachment1,
        attachment2: this.documents.attachment2,

      };
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.submitDisable = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/task/${this.$route.params.id}`
              : `${baseApi}/task`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((response) => {
              this.submitDisable = false;

              this.$router.push("/crm/task");
              this.$swal({
                title: "Submited",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                    ? `${response.data.success}`
                    : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.submitDisable = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
