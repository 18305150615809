var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[(_vm.$store.state.app.user_data&&(_vm.$store.state.app.user_data.role== "employee" || _vm.$store.state.app.user_data.role== "admin"))?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Assign To")]),_c('v-select',{attrs:{"placeholder":"None","options":['Employee','Channelpartner']},on:{"input":_vm.handleEmployeeOption},model:{value:(_vm.assigntotype),callback:function ($$v) {_vm.assigntotype=$$v},expression:"assigntotype"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Name")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Assign To"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"fullname","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"None","options":_vm.userOptions},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var fullname = ref.fullname;
var profile_image = ref.profile_image;
var username = ref.username;
var color = ref.color;
return [_c('b-avatar',{attrs:{"src":_vm.getprofileImage(profile_image)}}),_c('span',{class:'font-weight-bolder text-' + color},[_vm._v(" "+_vm._s(fullname)+" ("+_vm._s(username)+")")])]}}],null,true),model:{value:(_vm.assignto),callback:function ($$v) {_vm.assignto=$$v},expression:"assignto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Task Type")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Task Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","placeholder":"None","options":['Office Work', 'Personal Work', 'Social Work']},model:{value:(_vm.tasktype),callback:function ($$v) {_vm.tasktype=$$v},expression:"tasktype"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Task")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Task"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"Enter Task"},model:{value:(_vm.task),callback:function ($$v) {_vm.task=$$v},expression:"task"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Priority")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Priority"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","placeholder":"None","options":['High', 'Medium', 'Low']},model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Description")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"Enter Description","rows":"2"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Status")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","placeholder":"None","options":['Open', 'Pending', 'Complete']},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Dead Line")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Dead Line"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"config":{
                dateFormat: 'd/m/Y',
              }},model:{value:(_vm.deadline),callback:function ($$v) {_vm.deadline=$$v},expression:"deadline"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Attachment 1")]),_c('b-form-file',{attrs:{"placeholder":"Select Document","drop-placeholder":"Drop file here...","accept":"image/*"},on:{"input":function($event){return _vm.handleChangeFile($event, 'task', 'attachment1')}},model:{value:(_vm.documents.attachment1),callback:function ($$v) {_vm.$set(_vm.documents, "attachment1", $$v)},expression:"documents.attachment1"}})],1),_c('attachment',{attrs:{"data":_vm.documents.attachment1}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Attachment 2")]),_c('b-form-file',{attrs:{"placeholder":"Select Document","drop-placeholder":"Drop file here...","accept":"image/*"},on:{"input":function($event){return _vm.handleChangeFile($event, 'task', 'attachment2')}},model:{value:(_vm.documents.attachment2),callback:function ($$v) {_vm.$set(_vm.documents, "attachment2", $$v)},expression:"documents.attachment2"}})],1),_c('attachment',{attrs:{"data":_vm.documents.attachment2}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Remarks")]),_c('b-form-input',{attrs:{"type":"text","placeholder":"Enter Remarks"},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-4",attrs:{"variant":"primary","type":"submit","disabled":_vm.submitDisable},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onClickBack}},[_vm._v("Back ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }